import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef,
  Renderer2,
  ChangeDetectorRef,
  Input
} from "@angular/core";

import { Subscription } from "rxjs";
import { ConfigService } from "@shared/services/config.service";
import { CustomizerService } from "@shared/services/customizer.service";
import { FilterService, FilterType, ITemplateFilter } from "@shared/services/filter.service";
import { DataStatus } from "@api-client/enum/data.enum";
import { LayoutService } from "@shared/services/layout.service";
import { MapFilteredResponseInner } from "@api-client/index";
import { NgbCarousel, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DashboardDialogComponent } from "../dashboard-dialog/dashboard-dialog.component";
import { environment } from "@environments/environment";
import { AuthService } from "@shared/auth/auth.service";
import { RetrieveImagesPaginatedRequest } from "@api-client/model/retrieveImagesPaginatedRequest";
import { ImagesDialogComponent } from "../images-dialog/images-dialog.component";
import { Router } from "@angular/router";
import { UtilityService } from "@shared/services/utility.service";
import { EditRouteDialogComponent } from "../edit-route-dialog/edit-route-dialog.component";
import { NgxBootstrapConfirmService } from "ngx-bootstrap-confirm";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { LoadingService } from "@shared/services/loading.service";
import { DeleteRouteRequest } from "@api-client/model/deleteRouteRequest";


@Component({
  selector: "app-filters",
  templateUrl: "./filters.component.html",
  styleUrls: ["./filters.component.scss"] 
})
export class FiltersComponent implements OnInit, OnDestroy {
  @Output() onDelete: EventEmitter<any> = new EventEmitter();

  @ViewChild("customizer") customizer: ElementRef;
  @ViewChild(NgbCarousel, { static: false }) carousel: NgbCarousel;
  // public someRange: number[] = [0, 3000];
  // dateFrom;
  // dateTo;

  showRoute: boolean = false;
  maxDate: any = {};
  date: { year: number, month: number };
  size: string;
  isBgImageDisplay: boolean = true;
  isOpen = true;
  public filter: ITemplateFilter = this.filterService.templateFilter;
  layoutSub: Subscription;
  private _subscribers: Array<Subscription> = new Array<Subscription>();
  public config: any = {};
  statusItems: any = DataStatus;
  detailInfoList:Array<any> = [];
  constructor(
    public toastr: ToastrService,
    public utilityService:UtilityService,
    private router: Router,
    private authService:AuthService,
    private modalService: NgbModal,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private renderer: Renderer2,
    public filterService: FilterService,
    private cdRef: ChangeDetectorRef,
    private translate: TranslateService
  ) {
    const today = new Date();
    this.maxDate = { year: today.getFullYear(), month: (today.getMonth() + 1), day: today.getDate() };
    this.isOpen = !this.filter.filter.hidden;
    this.config = this.configService.templateConf;

    console.log('dateFrom', this.filter.dateFrom);
    console.log('dateTo', this.filter.dateTo);
    this.layoutSub = this.layoutService.showPanelInfo$.subscribe(c => {
      if (!c) return;
      this.showRoute = true;
      if (!this.isOpen) this.toggleFilter();
      this.cdRef.detectChanges();
    })
  }

  @Output() directionEvent = new EventEmitter<Object>();

  _detail: any;
  @Input() set detail(value) {
    if (value) {
      this._detail = value;
      this.cdRef.detectChanges();
      // this.stars = [false,false,false,false];
      // for(var a=0;a< value.routescore;a++)
      //   this.stars[a] = true;
    }
  }
  private _images:Array<any> = [];
  @Input() set images(val:Array<any>){
    this._images = val;
    this._getDetailInfoList();
    this.cdRef.detectChanges();
  }
  get images(){
    const founds = this._images.filter(c=>c.image_s3).map(m=>m.image_s3);
    return founds.length > 0? founds : [];
  } 
  
  get detail() {
    return this._detail;
  }
  get stars() {
    const arr = [false, false, false, false, false];
    for (var a = 0; a < (this.detail?.route?.score || 0); a++)
      arr[a] = true;
    return arr;
  }
  get isAdmin(){
    return this.authService.isAdmin();
  }
  _getDetailInfoList(){
    this.detailInfoList = [];
    this.detailInfoList.push({name:'MAP.LABEL.distance',value: this.getDistance()})
    const tot_accessibility_class = this._images.reduce(function(prev, cur) {
      return prev + (cur.accessibility_class || 0);
    }, 0);
    const tot_path_width_class = this._images.reduce(function(prev, cur) {
      return prev + (cur.path_width_class || 0);
    }, 0);
    const tot_roughness_class = this._images.reduce(function(prev, cur) {
      return prev + (cur.roughness_class || 0);
    }, 0);
    const tot_discontinuity_class = this._images.reduce(function(prev, cur) {
      return prev + (cur.discontinuity_class || 0);
    }, 0);
    const tot_terrain_types_class = this._images.reduce(function(prev, cur) {
      return prev + (cur.terrain_types_class || 0);
    }, 0);

    const tot_slope_class = this._images.reduce(function(prev, cur) {
      return prev + (cur.slope_class || 0);
    }, 0);
    //getValueMap
    //Math.round(
      
    const _accessibility = this.utilityService.getValueMap((tot_accessibility_class/this._images.length));
    const _path_width = this.utilityService.getValueMap((tot_path_width_class/this._images.length));
    const _roughness = this.utilityService.getValueMap((tot_roughness_class/this._images.length));
    const _discontinuity = this.utilityService.getValueMap((tot_discontinuity_class/this._images.length));
    const _terrain = this.utilityService.getValueMap((tot_terrain_types_class/this._images.length));
    const _slope = this.utilityService.getValueMap((tot_slope_class/this._images.length));
    
    this.detailInfoList.push({name:'MAP.LABEL.accessibility',value: _accessibility});
    this.detailInfoList.push({name:'MAP.LABEL.path_width',value: _path_width});
    this.detailInfoList.push({name:'MAP.LABEL.roughness',value: _roughness});
    this.detailInfoList.push({name:'MAP.LABEL.discontinuity',value: _discontinuity});
    this.detailInfoList.push({name:'MAP.LABEL.terrain',value: _terrain});
    this.detailInfoList.push({name:'MAP.LABEL.slope',value: _slope});
  }

  getRouteImg() {
    return "./assets/img/default-img.png"
  }
  ngOnInit() {
    //Set sidebar menu background color

  }
  deleteRow(){
    this.onDelete.emit(true);
  }
  openEditDialog(){
    const istance = this.modalService.open(EditRouteDialogComponent);
    istance.componentInstance.row = this.detail?.route;
    istance.componentInstance.onSave.subscribe((result: any) => {
      if (result) {
        this.detail.route.route_name = result.name;
        this.detail.route.route_description = result.description;
        this.cdRef.detectChanges();
        istance.close();
      }
    })
  }
  openImageDialog(){
    const id = this.detail?.route?.route_id;
    if(!id)return;
    const req:RetrieveImagesPaginatedRequest = {
      page: 1,
      route:id
    }
    const istance = this.modalService.open(ImagesDialogComponent,{windowClass:'full-screen-modal'});
    istance.componentInstance.reloadData.subscribe((val) => {
      if(val)this.router.navigate(['/maps',this._detail?.route?.route_id])
    })
    istance.componentInstance.retrieveImagesPaginatedRequest = req;
  }
  getLabelDifficulty() {
    const score = this.detail?.route?.score || 0;
    return this.utilityService.getValueMap(score);
    // switch (count) {
    //   case 0:
    //   case 1:
    //   case 2:
    //     return 'FACILE'
    //   case 3:
    //     return 'MEDIO'
    //   default:
    //     return 'DIFFICILE'
    // }
  }
  getDistance() {
    return (this.detail?.info?.km || 0).toFixed(1);
  }
  applyRangeFilter(ev) {
    //this.filterService.applytemplateFilterigChange();
  }

  apply() {
    this.filterService.itemsCount = undefined;
    this.filterService.applytemplateFilterigChange()
  }

  resetFilter(t: FilterType) {
    switch (t) {

      case FilterType.interval:
        this.filterService.resetType(FilterType.interval);
        // this.filter.dateFrom = undefined;
        // this.filter.dateTo = undefined;
        break;
      case FilterType.difficulty:
        this.filterService.resetType(FilterType.difficulty);
        break;
      case FilterType.name:
        this.filterService.resetType(FilterType.name);
        break;
      default:
      // code block
    }

  }
  setDate(e?) {
    //this.filterService.applytemplateFilterigChange();
  }
  closeRoute() {
    this.showRoute = false;
    this.layoutService.openInfo(false);
  }
  toggleFilter() {
    if (this.isOpen) {
      this.renderer.removeClass(this.customizer.nativeElement, "open");
      this.isOpen = false;
    } else {
      this.renderer.addClass(this.customizer.nativeElement, "open");
      this.isOpen = true;
    }
  }

  closeFilter() {
    this.renderer.removeClass(this.customizer.nativeElement, "open");
    this.isOpen = false;
  }

  openDashboard() {
    const id = this.detail?.route?.route_id;
    const istance = this.modalService.open(DashboardDialogComponent,{windowClass:'full-screen-modal'})
    istance.componentInstance.route = id;
    istance.componentInstance.title = this.detail?.route?.route_name; 
    istance.result.then((result) => {
      // `Closed with: ${result}`;
    }, (reason) => {
      // `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  ngOnDestroy() {
    this.showRoute = false;
    this.layoutService.openInfo(false);
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if(this._subscribers)this._subscribers.forEach(c=>c.unsubscribe());
  }


}

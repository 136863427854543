/**
 * FONDAZIONE-PORTAL
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-04-08T09:00:29Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface MapFilteredResponseInner { 
    route_id: string;
    route_name: string;
    route_description: string;
    entrance: string;
    entrance_position: string;
    exit: string;
    exit_position: string;
    score:number;
}

import { Injectable, OnDestroy } from '@angular/core';
import { ConfigService } from './config.service';
import { BehaviorSubject } from "rxjs";
import { LocalStorageService } from './storage.service';
import { SESSION_FILTER_KEY } from '../constants/app.constants';
import { DataStatus } from '@api-client/enum/data.enum';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: "root"
})
export class FilterService implements OnDestroy {

  private _defaultDateFrom = environment.options.defaultFilter.startDate;
  private _defaultDateTo = environment.options.defaultFilter.endDate;

  public rangeMinW: number = 0.0;
  public rangeMaxW: number = 300;
  public rangeCMin: number = 0.0;
  public rangeCMax: number = 1.0;

  public storedData: Array<any> = [];
  public itemsCount: number;
  public templateFilter: ITemplateFilter = this.setFilterValue();
  templateFilterSubject = new BehaviorSubject<ITemplateFilter>(this.templateFilter);
  templateFilter$ = this.templateFilterSubject.asObservable();

  constructor(private localStorageService: LocalStorageService, private config: ConfigService) {

  }
  public getAbsentValues = (arr1: Array<any>, arr2: Array<any>) => {
    let res = [];
    res = arr1.filter(el => {
      return !arr2.find(obj => {
        return el.track_id === obj.track_id;
      });
    });
    return res;
  };
  public _applyFilter(_POI_Layer: Array<any>, noCount?): Array<any> {

    //this.applytemplateFilterigChange();
    return _POI_Layer;

  }
  ngOnDestroy(): void {
    // this.localStorageService.saveData(SESSION_FILTER_KEY, this.templateFilter);
  }
  setFilterValue(): ITemplateFilter {
    const _session = this.localStorageService.getData(SESSION_FILTER_KEY);

    if (_session) return this.templateFilter = _session;
    else {

      const today = new Date();
      const priorDateTimeStamp = new Date().setDate(today.getDate() - 30);
      const priorDate = new Date(priorDateTimeStamp);

      this._defaultDateTo = { year: today.getFullYear(), month: (today.getMonth() + 1), day: today.getDate() }
      this._defaultDateFrom = { year: (today.getFullYear() - 2), month: (today.getMonth() + 1), day: (today.getDate()) }

      return this.templateFilter = {
        dateFrom: this._defaultDateFrom,
        dateTo: this._defaultDateTo,
        difficulty_list: [],
        name: '',
        filter: {
          hidden: true
        }
      };
    }
  }
  switchDifficulty(e, type) {
    const index = this.templateFilter.difficulty_list.indexOf(type);
    if (index > - 1 && !e.target.checked)
      this.templateFilter.difficulty_list.splice(index, 1);
    else {
      if (index == -1) this.templateFilter.difficulty_list.push(type);
    }

    // this.applytemplateFilterigChange();
  }

  resetType(t: FilterType) {

    switch (t) {
      case FilterType.difficulty:
        this.templateFilter.difficulty_list = [];
        break;
      case FilterType.interval:
        this.templateFilter.dateFrom = this._defaultDateFrom;
        this.templateFilter.dateTo = this._defaultDateTo;
        break;
      case FilterType.name:
        this.templateFilter.name = '';
        break;
      default:
      // code block
    }

    this.templateFilterSubject.next(this.templateFilter);
  }

  applytemplateFilterigChange() {
    this.templateFilterSubject.next(this.templateFilter);
  }
  private _getSatusArray(): Array<string> {
    const statusArr = Object.keys(DataStatus);
    const statusArrOfNum = statusArr.map(str => {
      return DataStatus[str];
    });
    return statusArrOfNum.filter(c => c != DataStatus.Discarded && c != DataStatus.Fixed);
  }

}
export interface ITemplateFilter {

  dateFrom: any | undefined;         //{day:21, month:10, year:2022}
  dateTo: any | undefined;           //{day:21, month:10, year:2022}
  name: string;
  difficulty_list: Array<string>;
  filter: any;
}
export enum FilterType {
  type = 0,
  name,
  interval,
  confidence,
  difficulty
}
import { map } from 'rxjs/operators';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef,
  Renderer2,
  Input,
  SimpleChanges,
  ChangeDetectorRef
} from "@angular/core";
import { DataStatus } from "@api-client/enum/data.enum";
import { DefaultService } from "@api-client/index";
import { ApproveImageRequest } from "@api-client/model/approveImageRequest";
import { RetrieveImagesPaginatedRequest } from "@api-client/model/retrieveImagesPaginatedRequest";
import { RetrieveImagesPaginatedResponse } from "@api-client/model/retrieveImagesPaginatedResponse";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { SlideUpDownPanel } from "@shared/animations/custom-animations";
import { AuthService } from "@shared/auth/auth.service";

import { FilterService } from "@shared/services/filter.service";
import { LoadingService } from "@shared/services/loading.service";
import { UtilityService } from "@shared/services/utility.service";
import { NgxMasonryComponent, NgxMasonryOptions } from "ngx-masonry";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { environment } from '@environments/environment';


@Component({
  selector: "app-images-dialog",
  templateUrl: "./images-dialog.component.html",
  styleUrls: ["./images-dialog.component.scss"],
  animations: [SlideUpDownPanel]
})
export class ImagesDialogComponent implements OnInit, OnDestroy {
  @Output() reloadData: EventEmitter<any> = new EventEmitter();
  private _subscribers: Array<Subscription> = new Array<Subscription>();
  defaultImage: string = "./assets/img/oval.svg";
  images:Array<any> = [];
  images_selected: Array<string> = [];
  isChanged: boolean;
  masonryOptions: NgxMasonryOptions = {
    gutter: 10,
    // itemSelector: "#item",
    // columnWidth: "#item",
    // horizontalOrder: true,
    // fitWidth: true,
    // percentPosition: true,
    animations: {}
  };
  isOpen: boolean;

  page: any = { total_pages: undefined, current_page: 1 };


  private _readyOnly: boolean = false;
  set readyOnly(value: boolean) {
    this._readyOnly = value;
  }
  get readyOnly() { return this._readyOnly }

  private _retrieveImagesPaginatedRequest: RetrieveImagesPaginatedRequest;
  set retrieveImagesPaginatedRequest(value: RetrieveImagesPaginatedRequest) {
   
    this._retrieveImagesPaginatedRequest = value;
    this.callServer();
  }
  get retrieveImagesPaginatedRequest(): RetrieveImagesPaginatedRequest {
    return this._retrieveImagesPaginatedRequest;
  }
  constructor(

    private translate: TranslateService,
    private modalService: NgbModal,
    public toastr: ToastrService,
    private cdRef: ChangeDetectorRef,
    private dataControllerService: DefaultService,
    private loadingService: LoadingService,
    public utilityService: UtilityService
  ) {
  }
  ngOnInit() {




  }
  save() {
    this.loadingService.show();

    const req: ApproveImageRequest = {
      lista_segmenti: this.images_selected
    }
    this._subscribers.push(
      this.dataControllerService.adminRouteApproveImagesPost(req).subscribe((res: RetrieveImagesPaginatedResponse) => {

        if (res && res["errorMessage"]) {
          this.toastr.error(res["errorMessage"], '');
        }
        else {
          this.isChanged = true;
          this.toastr.success('', this.translate.instant("COMMON.MESSAGES.SUCCESS_TITLE"));
        }
        this.loadingService.hide();
        this.reloadData.emit(true);
      })
    )
  }
  private callServer() {
    this.loadingService.show();
    //this.retrieveImagesPaginatedRequest.route = 'd1199c44-eaea-442a-a9b3-0ccdd135d1ce';
    if (!this.readyOnly) {
      this._subscribers.push(
        this.dataControllerService.adminRouteRetrieveImagesPost(this.retrieveImagesPaginatedRequest).subscribe((res: RetrieveImagesPaginatedResponse) => {
          this.loadData(res.data);
          this.page.total_pages = res.total_pages;
        })
      )
    }
    else {
      this.loadData();
      this.page.total_pages = this.images.length > 0 ? 1 : 0;
      this.loadingService.hide();
    }
  }

  getImg(val): string {
    //return this.utilityService.getAuthImg(val);
    return this.readyOnly ? val.image_s3 : val.image_path;
  }
  loadMore() {
    this.retrieveImagesPaginatedRequest.page++;
    this.callServer();
  }
  loadData(items?: any[]) {
    if (items) {
      items.forEach(i => {
        const idx = this.images_selected.findIndex(c => c == i.id_segment);
        if (idx == -1 && i.image_approved) this.images_selected.push(i.id_segment);

        const idx_exist = this.images.findIndex(c => c.id_segment == i.id_segment);
        if (idx_exist == -1) this.images.push(i);
      })
    }
    //this.images = this._generateImagesList();
    this.isOpen = true;
    // if(this.masonry){
    //   this.masonry.reloadItems();
    //   this.masonry.layout();
    // }
    this.cdRef.detectChanges();
    const _self = this;
    setTimeout(function () {
      if (_self.loadingService)
        _self.loadingService.hide()
    }, 1500);
  }
  addSelection(item) {
    if (this.readyOnly) return;

    this.isChanged = true;

    const idx = this.images_selected.findIndex(c => c == item.id_segment);
    if (idx > -1)
      this.images_selected.splice(idx, 1);
    else this.images_selected.push(item.id_segment);
  }
  isSelected(item): boolean {
    if (this.readyOnly) return false;

    const idx = this.images_selected.findIndex(c => c == item.id_segment);
    return idx > -1;
  }


  close() {
    this.modalService.dismissAll();
    //if(this.isChanged)this.utilityService.reloadAllData();
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.images = [];
    if (this._subscribers) this._subscribers.forEach(s => s.unsubscribe());
  }


}

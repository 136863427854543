import { Routes, RouterModule } from '@angular/router';
import { AuthorizeAdminGuard } from '@shared/auth/auth-guard-admin.service';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
 
  {
    path: 'maps',
    loadChildren: () => import('../../modules/map.module/map.module').then(m => m.MapModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('../../modules/admin.module/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('../../pages/full-pages/full-pages.module').then(m => m.FullPagesModule)
  }
 
 
]; 

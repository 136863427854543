import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef,
  Renderer2,
  Input,
  SimpleChanges
} from "@angular/core";
import { DataStatus } from "@api-client/enum/data.enum";
import { DefaultService } from "@api-client/index";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "@shared/auth/auth.service";

import { FilterService } from "@shared/services/filter.service";
import { LoadingService } from "@shared/services/loading.service";
import { NgxBootstrapConfirmService } from "ngx-bootstrap-confirm";
import { Subscription } from "rxjs";


@Component({
  selector: "app-label-status-badge",
  templateUrl: "./label-status.component.html",
  styleUrls: ["./label-status.component.scss"]
})
export class LblStatusBadgeComponent implements OnInit, OnDestroy {
  @Output() onChangeStatus = new EventEmitter<boolean>();
  private _element:any;
  @Input()large:boolean;
  @Input()editable:boolean;
  statusItems:any = DataStatus;
  @Input() set element(value: any) {
    this._element = value;
 }
  get element(): any {
     return this._element;
 }
  statusEnum = DataStatus;
  private _subscribers: Array<Subscription> = new Array<Subscription>();
  public canEdit: boolean = true;
  constructor(
    private authService: AuthService,
    private dataControllerService: DefaultService,
    private loadingService:LoadingService,
    private ngxBootstrapConfirmService: NgxBootstrapConfirmService,
    private translate: TranslateService,
    public filterService: FilterService
  ) {
    this.canEdit = this.authService.canEdit();
  }
  getStatusLbl():string{
    return this.translate.instant("COMMON.DETECTION_STATUSES." + this._element.status) ; 
  }
  ngOnInit() {
  }

  setStatus(newstatus){
     let val =  DataStatus[newstatus] as unknown as number;

    if(!this.canEdit)return;
    //this.onChangeStatus.emit(val);
    //this.status = val;
    let options ={
      title: this.translate.instant("LIST.CONFIRM_STATUS_CHANGE"),
      confirmLabel: this.translate.instant("COMMON.CONFIRM"),
      declineLabel: this.translate.instant("COMMON.CANCEL"),
      class: "confirm-modal"
    }
    this.ngxBootstrapConfirmService.confirm(options).then((res: boolean) => {
      if (res) {
        this.loadingService.show();
        const req:any = {
          entity_uuid: this._element.entity_uuid,
          status: val
        }
        // this._subscribers.push(
        //   this.dataControllerService.detectionStatusPost(req).subscribe(
        //     (data) => {
        //       console.log('setStatus');
        //       this._element.status = val;
        //       this.loadingService.hide();
        //       this.onChangeStatus.emit(true);
        //     }
        //   ));
        
      } else {
        console.log('Cancel');
      }
    });
  }


  ngOnDestroy() {
    if (this._subscribers)this._subscribers.forEach(s => s.unsubscribe());
  }
  

}

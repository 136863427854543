import { NominatimResponse, UtilityService } from '@shared/services/utility.service';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef,
  Renderer2,
  Input,
  ChangeDetectorRef
} from "@angular/core";

import { Subscription } from "rxjs";
import { FilterService, FilterType, ITemplateFilter } from "@shared/services/filter.service";
import { DefaultService } from '@api-client/index';


@Component({
  selector: "app-input-address",
  template: `<span class="address" ngbTooltip="{{geoAdress}}" *ngIf="data"> {{getName()}} <i *ngIf="!nameResolved" class="ft-refresh-ccw font-medium-1 spinner align-middle ml-2"></i></span>`,
  styleUrls: ["./input-address.component.scss"]
})
export class InputAddressComponent implements OnInit, OnDestroy {
 
  addressName:string;
  nameResolved:boolean;
  layoutSub: Subscription;
  nameSub: Subscription

  private _data:any;
  geoAdress:string;
  @Output() completedEvent = new EventEmitter<boolean>();

  @Input()onlyAdress:boolean;

  @Input()index:number;

  @Input() set data(value: any) {
    const _self =this;
    _self._data = value;
    if(_self._data){
      const _address = _self._data.location;
      this.geoAdress = this.utilityService.getLocation(_address, true).toString() ;
      // this.utilityService.addAddressRequest(_self.index);
      // this.nameSub = this.utilityService.addressRequestSubject.subscribe((item:any)=>{
      //   if(item.index != this.index)return;
      //   setTimeout(function(){
      //     _self.getAddressInfo(_address);
      //   },300);
      // })

      setTimeout(function(){
        _self.getAddressInfo(_address);
      },300);
     
      
    }
  }
  get data(): any {
    return this._data;
  }

  constructor(
    private dataControllerService: DefaultService,
    private cdRef: ChangeDetectorRef,
    private utilityService:UtilityService,
    private renderer: Renderer2,
    public filterService: FilterService,
  ) {
   
  }

  @Output() directionEvent = new EventEmitter<Object>();

  ngOnInit() {

  }

  getName(): string {
    return this.nameResolved? this.addressName : (this.onlyAdress ? '' : this.geoAdress);
  }

  private getAddressInfo(address){
    
    const param = this.utilityService.getLocation(this._data.location, true);
   
    // this.nameSub = this.dataControllerService.locationInverseGeocodeGet(String(param[0]), String(param[1])).subscribe(
    //   (result:GetLocationReverseGeocodeResponse) => {
    //   this.nameResolved = true;
    //   if(result.label)
    //   this.addressName = result.label;
    //   this.completedEvent.emit(true);
    //   this._clearNameSub();
    //   //this.utilityService.removeAddressRequest(this.index);
    //   this.cdRef.detectChanges();
    // });
  }
  private _clearNameSub(){
    if(this.nameSub)
    this.nameSub.unsubscribe()
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    this._clearNameSub();
  }


}

/**
 * FONDAZIONE-PORTAL
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-04-08T09:00:29Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface MapFilteredRequest { 
    lat_min: number;
    lat_max: number;
    lon_min: number;
    lon_max: number;
    route_name?: string;
    difficulty_list?: Array<string>;
    start_date?: number;
    end_date?: number;
}



<div class="modal-header">
	<h2 class="accent-font">{{title}}</h2>
	<button type="button" class="close" aria-label="Close" (click)="close()" >
	  <span aria-hidden="true">&times;</span>
	</button>
  
  </div>

  <div class="modal-body">
	<div class="row">
		<div class="col-12 col-lg-6">
			<div class="card-content">
				<div class="card-header">
				  <div>
					<span class="font-small-3 text-uppercase accent-font">Percorso accidentato</span>
				  </div>
				</div>
				<div class="card-body">
				  <div class="chart">
					<app-x-chart [chartType]="'bar'" 
					  [chartOptions]="kpiChartOptions" [isLoading]="isLoading.c1" [chartData]="chartSegmentData"
					  [chartLabels]="chartSegmentLabel"></app-x-chart>
				  </div>
				</div>
			  </div>
		</div>
		<div class="col-12 col-lg-6">
			<div class="card-content">
				<div class="card-header">
				  <div>
					<span class="font-small-3 text-uppercase accent-font">Larghezza minima</span>
				  </div>
				</div>
				<div class="card-body">
				  <div class="chart">
					<app-x-chart [chartType]="'bar'" 
					  [chartOptions]="kpiChartOptions" [isLoading]="isLoading.c1" [chartData]="chartSegmentData2"
					  [chartLabels]="chartSegmentLabel"></app-x-chart>
				  </div>
				</div>
			  </div>
		</div>
		<div class="col-12 col-lg-6">
			<div class="card-content">
				<div class="card-header">
				  <div>
					<span class="font-small-3 text-uppercase accent-font">Pendenza</span>
				  </div>
				</div>
				<div class="card-body">
				  <div class="chart">
					<app-x-chart [chartType]="'bar'" 
					  [chartOptions]="kpiChartOptions" [isLoading]="isLoading.c2" [chartData]="chartSlopeData"
					  [chartLabels]="chartSlopeLabel"></app-x-chart>
				  </div>
				</div>
			  </div>
		</div>
		<div class="col-12 col-lg-6">
			<div class="card-content">
				<div class="card-header">
				  <div>
					<span class="font-small-3 text-uppercase accent-font">Discontinuità</span>
				  </div>
				</div>
				<div class="card-body">
				  <div class="chart">
					<app-x-chart [chartType]="'bar'" 
					  [chartOptions]="kpiChartOptions" [isLoading]="isLoading.c3" [chartData]="chartDiscontinuityData"
					  [chartLabels]="chartDiscontinuityLabel"></app-x-chart>
				  </div>
				</div>
			  </div>
		</div>
	</div>
	<div class="row">
		<div class="col-lg-12">
			<div class="card-content">
				<div class="card-header">
				  <div>
					<span class="font-small-3 text-uppercase accent-font">Terreno</span>
				  </div>
				</div>
				<div class="card-body">
				  <div class="chart">
					<app-x-chart [chartType]="'bar'" 
					  [chartOptions]="kpiChartOptions" [isLoading]="isLoading.c4" [chartData]="chartTerrainsData"
					  [chartLabels]="chartTerrainsLabel"></app-x-chart>
				  </div>
				</div>
			  </div>
		</div>
	</div>
  </div>
  
<!-- Theme customizer Starts-->

<div #customizer class="customizer d-lg-none d-xl-block filter" [ngClass]="{open: isOpen}">
  <a *ngIf="showRoute" class="customizer-close floating" (click)="closeRoute()">
    <i class="ft-x font-large-1"></i></a>
  <a *ngIf="!showRoute" class="customizer-close white" (click)="closeFilter()">
    <i class="icon-fm-close font-large-1"></i></a>
  <a class="customizer-toggle bg-primary" id="customizer-toggle-icon" (click)="toggleFilter()">
    <i class=" text-secondary align-middle"
      [ngClass]="{'icon-fm-right font-medium-5':isOpen,'icon-fm-left font-medium-5':!isOpen}"></i>

  </a>
  <div [perfectScrollbar] class="customizer-content p-3 ps-container"
    [ngClass]="{'ps-theme-dark': !showRoute,'panel-info': showRoute}" data-ps-id="df6a5ce4-a175-9172-4402-dabd98fc9c0a">
 
    <ng-container *ngIf="!showRoute;else detail_template">

      <h4 class="text-uppercase accent-font">{{'FILTERS.TITLE'| translate}}</h4>
      <!-- <p>{{'FILTERS.DESCRIPTION'|translate}} </p> -->
      <!-- Options Starts-->
      <hr>
      <div class="ct-layout">

        <h6 class="mb-4 d-flex align-items-center"><i class="icon-fm-route font-large-1 mr-2"></i><span
            class="accent-font" >{{'MAP.LABEL.route' | translate}}</span>
        </h6>
        <button ngbTooltip="Reset" type="button"
          class="bt-reset btn btn-sm btn-outline-secondary  btn-icon round mr-1 mb-1 d-inline"><i
            class="ft-rotate-ccw"></i></button>
        <div class="layout-switch">
          <form>
            <div class="form-row">
              <div class="col-md-12 col-12">
                <div class="form-group">
                  <div class="input-group round">
                    <input *ngIf="filterService.templateFilter" class="form-control round" [[(ngModel)]="filterService.templateFilter.name" [placeholder]="'MAP.LABEL.search' | translate">
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <hr>
      <!-- Options Starts-->
      <div class="ct-layout">

        <h6 class="mb-4 d-flex align-items-center"><i class="icon-fm-filter font-large-1 mr-2"></i><span
            class="accent-font" >{{'MAP.LABEL.difficulty' | translate}}</span>
        </h6>
        <button (click)="resetFilter(4)" ngbTooltip="Reset" type="button"
          class="bt-reset btn btn-sm btn-outline-secondary  btn-icon round mr-1 mb-1 d-inline"><i
            class="ft-rotate-ccw"></i></button>
        <div class="layout-switch white">
          <div class="checkbox d-inline-block light-layout mr-3">
            <input id="ll-switch" type="checkbox" name="layout-switch" [checked]="filter.difficulty_list.indexOf('easy') > -1"
              (change)="filterService.switchDifficulty($event,'easy')">
            <label for="ll-switch" >{{'MAP.LABEL.easy' | translate}}</label>
          </div>
          <div class="checkbox d-inline-block dark-layout mr-3">
            <input id="dl-switch" type="checkbox" name="layout-switch" [checked]="filter.difficulty_list.indexOf('medium') > -1"
              (change)="filterService.switchDifficulty($event,'medium')">
            <label for="dl-switch" >{{'MAP.LABEL.medium' | translate}}</label>
          </div>
          <div class="checkbox d-inline-block transparent-layout">
            <input id="tl-switch" type="checkbox" name="layout-switch" [checked]="filter.difficulty_list.indexOf('hard') > -1"
              (change)="filterService.switchDifficulty($event,'hard')">
            <label for="tl-switch" >{{'MAP.LABEL.hard' | translate}}</label>
          </div>
        </div>

      </div>

      <hr>
      <!-- Options Starts-->
      <div class="ct-layout">

        <h6 class="mb-4 d-flex align-items-center"><i class="icon-fm-calendar font-large-1 mr-2"></i><span
            class="accent-font" >{{'MAP.LABEL.date' | translate}}</span>
        </h6>

        <button (click)="resetFilter(2)" ngbTooltip="Reset" type="button"
          class="bt-reset btn btn-sm btn-outline-secondary  btn-icon round mr-1 mb-1 d-inline"><i
            class="ft-rotate-ccw"></i></button>
        <div class="layout-switch">
          <form>
            <div class="form-row">
              <div class="col-md-6 col-12">
                <div class="form-group">
                  <div class="input-group round">
                    <input class="form-control round" placeholder="{{'FILTERS.START_DATE'|translate}}" name="dp1"
                      [(ngModel)]="filter.dateFrom" ngbDatepicker [maxDate]="maxDate" #d2="ngbDatepicker">
                    <div class="input-group-append">
                      <div class="input-group-text" (click)="d2.toggle()">
                        <i class="fa fa-calendar" style="cursor: pointer;"></i>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
              <div class="col-md-6 col-12">
                <div class="form-group">
                  <div class="input-group round">
                    <input class="form-control round" placeholder="{{'FILTERS.END_DATE'| translate}}" name="dp2"
                      [(ngModel)]="filter.dateTo" ngbDatepicker [maxDate]="maxDate" [minDate]="filter.dateFrom"
                      #d3="ngbDatepicker">
                    <div class="input-group-append">
                      <div class="input-group-text" (click)="d3.toggle()">
                        <i class="fa fa-calendar" style="cursor: pointer;"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </form>
        </div>
      </div>
      <hr>
      <div class="ct-layout">
        <div class="mb-1 text-center">
          <button (click)="apply()" type="button"
            class="btn btn-secondary round mb-1 d-inline accent-font font-medium-2">{{'FILTERS.LOAD' | translate}}</button>
        </div>
      </div>
      <h6 class="count-label">
        {{filterService.itemsCount}}
      </h6>
    </ng-container>
  
    <ng-template #detail_template>
      
      <h3 class="head accent-font"><span>{{'MAP.LABEL.detail' | translate}}</span>
      </h3>
      
      <div class="detail-body">
        <div class="delete-wrap" *ngIf="isAdmin" >
          <button type="button" 
          class="btn btn-primary round mb-1 d-inline  accent-font" (click)="deleteRow()" >{{'LIST.DELETE' | translate}}</button>
        </div>
        <p class="title font-medium-3 no-margin accent-font">{{detail?.route?.route_name || 'nd'}}
          <button class="btn btn-primary small round btn-settings" *ngIf="isAdmin" (click)="openEditDialog()">
            <span class="icon-fm-settings"></span>
          </button>
        </p>
        <span _ngcontent-wbc-c165="" class="time d-lg-flex justify-content-start align-items-center opacity">
          <!-- <i _ngcontent-wbc-c165="" class="icon-fm-right"></i> -->
           {{detail?.route?.entrance}} - {{detail?.route?.exit}}</span>
        <p class="font-small-3">{{detail?.route?.route_description  || 'nd'}}</p>
          <div class="img-container">
            <button class="btn btn-primary small round btn-settings" *ngIf="isAdmin" (click)="openImageDialog()">
              <span class="icon-fm-settings"></span>
            </button>
            <img [src]="getRouteImg()" *ngIf="images.length == 0" />
            <ngb-carousel *ngIf="images.length > 0" #carousel [interval]="5000">
              <ng-template ngbSlide *ngFor="let item of images">
                <img [src]="item" />
              </ng-template>
            </ngb-carousel>
          </div>
        <div class="rating">
          <div class="left">
            <span class="lbl accent-font" >{{'MAP.LABEL.difficulty' | translate}}</span>
            <span class="value accent-font">{{getLabelDifficulty()}}</span>
          </div>
          <div class="right">
            <div class="stars">
              <span *ngFor="let item of stars" [ngClass]="{'icon-fm-star':item,'icon-fm-star_outline':!item}"  ></span>
            </div>
          </div>
        </div>
        <div class="list mb-3">
          <div class="item" *ngFor="let item of detailInfoList;let index = index">
            <span class="lbl accent-font">{{item.name | translate}}</span>
            <span class="value ">{{item.value}} <span *ngIf="index == 0">{{'MAP.LABEL.km' | translate}}</span></span>
          </div>
          <!-- <div class="item">
            <span class="lbl accent-font">Durata</span>
            <span class="value">4:50 h</span>
          </div>
          <div class="item">
            <span class="lbl accent-font">Salita
            </span>
            <span class="value">286 m</span>
          </div>
          <div class="item">
            <span class="lbl accent-font">Discesa
            </span>
            <span class="value">286 m</span>
          </div> -->
        </div>
        <div class="ct-layout ">
          <div class="mb-5 text-center">
           
            <button type="button"
              class="btn btn-primary round mb-1 d-inline font-medium-2  accent-font" (click)="openDashboard()">Dashboard</button>
          </div>
        </div>
        
      </div>
    </ng-template>

  </div>
  <!-- Theme customizer Ends-->
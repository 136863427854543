import { UserInformationResponse } from "@api-client/index";
import { TOKEN_KEY } from "@shared/constants/app.constants";
import { LocalStorageService } from "@shared/services/storage.service";


declare var window: any;

export class DynamicEnvironment {
  constructor() {
  }
  
  private _user:any;
  public get user(){
    return this._user
  }
  public set user(value:any){
    this._user = value
  }

  public get logo(){
    return this.options.logo || 'assets/img/logo-fondazione-mazzola.png'
  }
  public get environment() {
    return window.config.environment;
  }
  public get cognitoConfig() {
    return window.config.cognito;
  }
  public get apiConfig() {
    return window.config.api;
  }
  public get options() {
    return window.config.options;
  }
  public get defaultMapOptions() {
    return window.config.defaultMapOptions;
  }
}
import { Component, ViewContainerRef, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import { environment } from "environments/environment";
import { AuthService } from "@shared/auth/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { NgcCookieConsentService, NgcNoCookieLawEvent } from 'ngx-cookieconsent';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  logOutSubscription: Subscription;

    //keep refs to subscriptions to be able to unsubscribe later
    private popupOpenSubscription!: Subscription;
    private popupCloseSubscription!: Subscription;
    private initializingSubscription!: Subscription;
    private initializedSubscription!: Subscription;
    private initializationErrorSubscription!: Subscription;
    private statusChangeSubscription!: Subscription;
    private revokeChoiceSubscription!: Subscription;
    private noCookieLawSubscription!: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router,
    public  translate: TranslateService,
    private ccService: NgcCookieConsentService
  ) {
    // const a = environment["firebaseConfig"];
    // console.log('config',environment.environment);

    const currentLanguage = this.translate.getBrowserLang();
    this.translate.setDefaultLang('it');
    this.translate.use(currentLanguage);
 
  }

  ngOnInit() {
    this.subscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => window.scrollTo(0, 0));

    // this.logOutSubscription = this.authService.authenticationSubject.subscribe(c=>{
    //     if(!c)this.router.navigate(['/pages/login']);
    // })

    this.translate.get(["COOKIE_BANNER.MESSAGE","COOKIE_BANNER.DISMISS","COOKIE_BANNER.ALLOW","COOKIE_BANNER.DENY","COOKIE_BANNER.LINK","COOKIE_BANNER.POLICY"])
      .subscribe(data => {
        this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;
        // Override default messages with the translated ones
        this.ccService.getConfig().content.message = data["COOKIE_BANNER.MESSAGE"];
        this.ccService.getConfig().content.dismiss = data["COOKIE_BANNER.DISMISS"];
        this.ccService.getConfig().content.allow = data["COOKIE_BANNER.ALLOW"];
        this.ccService.getConfig().content.deny = data["COOKIE_BANNER.DENY"];
        this.ccService.getConfig().content.link = data["COOKIE_BANNER.LINK"];
        this.ccService.getConfig().content.policy = data["COOKIE_BANNER.POLICY"];
        this.ccService.destroy(); // remove previous cookie bar (with default messages)
        this.ccService.init(this.ccService.getConfig()); // update config with translated messages
      });
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
    else if (this.logOutSubscription) this.logOutSubscription.unsubscribe();

      // unsubscribe to cookieconsent observables to prevent memory leaks
      this.popupOpenSubscription.unsubscribe();
      this.popupCloseSubscription.unsubscribe();
      this.initializingSubscription.unsubscribe();
      this.initializedSubscription.unsubscribe();
      this.initializationErrorSubscription.unsubscribe();
      this.statusChangeSubscription.unsubscribe();
      this.revokeChoiceSubscription.unsubscribe();
      this.noCookieLawSubscription.unsubscribe();
  }
}


<div class="modal-header">
	<h2 class="accent-font" ><span *ngIf="!readyOnly;else normal_title">{{'LIST.MANAGE_IMAGES' | translate}}</span></h2>
	<ng-template #normal_title>
		<span>{{'LIST.VIEW_IMAGES' | translate}}</span>
	</ng-template>
	<button type="button" *ngIf="isChanged && !readyOnly"
		class="btn btn-primary round d-inline font-small small  accent-font " (click)="save()">{{'COMMON.CONFIRM' | translate}}</button>
	<button type="button" class="close" aria-label="Close" (click)="close()" >
	  <span aria-hidden="true">&times;</span>
	</button>
  
  </div>

  <div class="modal-body">
	<p class="black" *ngIf="page.total_pages != undefined && page.total_pages === 0">
	{{'COMMON.MESSAGES.NO_DATA_GENERIC' | translate}} 
	</p>
	<section class="masonry">
		<ngx-masonry [options]="masonryOptions" [ordered]="true">
			<div   class="masonry-item" ngxMasonryItem *ngFor="let item of images"  (click)="addSelection(item)" [ngClass]="{'img-selected': isSelected(item) && !readyOnly, 'cursor-pointer':!readyOnly}">
				<div class="triangle-top-right"><span class="ft-check"></span></div>
				 <img masonryLazy loading="lazy" alt="" width="100%" [src]="getImg(item)" /> 
				<!-- <img alt="" [src]="item.image" width="100%"/> -->
			</div>
		</ngx-masonry>
	</section>
	<div class="load-more" *ngIf="retrieveImagesPaginatedRequest && page.total_pages > retrieveImagesPaginatedRequest.page">
		<button type="button"
		class="btn btn-primary small round mb-1 d-inline font-small  accent-font" (click)="loadMore()">{{'COMMON.LOAD_MORE' | translate}}</button>
	</div>
</div>
  
import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { ClickOutsideModule } from 'ng-click-outside';
import { AutocompleteModule } from './components/autocomplete/autocomplete.module';
import { PipeModule } from '@shared/pipes/pipe.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NouisliderModule } from 'ng2-nouislider';
import { NgxBootstrapConfirmModule } from 'ngx-bootstrap-confirm';
import { NgxMasonryModule } from 'ngx-masonry';
import { ChartsModule } from 'ng2-charts';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
//COMPONENTS
import { NavbarComponent } from "./components/navbar/navbar.component";
import { VerticalMenuComponent } from "./components/vertical-menu/vertical-menu.component";
import { CustomizerComponent } from './components/customizer/customizer.component';
import { NotificationSidebarComponent } from './components/notification-sidebar/notification-sidebar.component';
import { InputAddressComponent } from './components/input-address/input-address.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { FiltersComponent } from './components/filters/filters.component';
import { LblStatusBadgeComponent } from './components/label-status/label-status.component';
import { RatingClassComponent } from './components/rating-class/rating-class.component'; 
import {ImagesDialogComponent}from './components/images-dialog/images-dialog.component'; 
//DIRECTIVES
import { ToggleFullscreenDirective } from "./directives/toggle-fullscreen.directive";
import { SidebarLinkDirective } from './directives/sidebar-link.directive';
import { SidebarDropdownDirective } from './directives/sidebar-dropdown.directive';
import { SidebarAnchorToggleDirective } from './directives/sidebar-anchor-toggle.directive';
import { SidebarDirective } from './directives/sidebar.directive';
import { TopMenuDirective } from './directives/topmenu.directive';
import { TopMenuLinkDirective } from './directives/topmenu-link.directive';
import { TopMenuDropdownDirective } from './directives/topmenu-dropdown.directive';
import { TopMenuAnchorToggleDirective } from './directives/topmenu-anchor-toggle.directive';
import {UiImageLoaderDirective}from './directives/img-loading.directive';
import { XChartComponent } from './components/x-chart/x-chart.component';
import { DashboardDialogComponent } from './components/dashboard-dialog/dashboard-dialog.component';
import { EditRouteDialogComponent } from './components/edit-route-dialog/edit-route-dialog.component';
import { PclDialogComponent } from './components/pcl-dialog/pcl-dialog.component';

@NgModule({
    exports: [
        ChartsModule,
        CommonModule,
        NavbarComponent,
        SpinnerComponent,
        VerticalMenuComponent,
        CustomizerComponent,
        FiltersComponent,
        InputAddressComponent,
        LblStatusBadgeComponent,
        RatingClassComponent, 
        NotificationSidebarComponent, 
        ImagesDialogComponent,
        PclDialogComponent,
        EditRouteDialogComponent,
        DashboardDialogComponent,
        ToggleFullscreenDirective,
        SidebarDirective,
        TopMenuDirective,
        UiImageLoaderDirective,
        NgbModule,
        NouisliderModule,
        NgSelectModule,
        NgxBootstrapConfirmModule,
        XChartComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        FormsModule,
        OverlayModule,
        ReactiveFormsModule ,
        PerfectScrollbarModule,
        ClickOutsideModule,
        AutocompleteModule,
        PipeModule,
        NouisliderModule,
        NgSelectModule,
        NgxMasonryModule,
        ChartsModule,
        TranslateModule
    ],
    declarations: [
        NavbarComponent,
        VerticalMenuComponent,
        CustomizerComponent,
        FiltersComponent,
        InputAddressComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        SidebarLinkDirective,
        SidebarDropdownDirective,
        SidebarAnchorToggleDirective,
        SidebarDirective,
        TopMenuLinkDirective,
        TopMenuDropdownDirective,
        TopMenuAnchorToggleDirective,
        TopMenuDirective,
        UiImageLoaderDirective,
        SpinnerComponent,
        LblStatusBadgeComponent,
        RatingClassComponent,
        ImagesDialogComponent,
        PclDialogComponent,
        DashboardDialogComponent,
        XChartComponent,
        EditRouteDialogComponent
    ]
})
export class SharedModule { }

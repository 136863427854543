/**
 * FONDAZIONE-PORTAL
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-04-08T09:00:29Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { AdminRetrieveRoutesResponse } from '../model/adminRetrieveRoutesResponse';
import { ApproveImageRequest } from '../model/approveImageRequest';
import { CreateUserRequest } from '../model/createUserRequest';
import { Empty } from '../model/empty';
import { EnableDisableUserRequest } from '../model/enableDisableUserRequest';
import { ListUsersResponse } from '../model/listUsersResponse';
import { MapFilteredRequest } from '../model/mapFilteredRequest'; 
import { MapFilteredResponse } from '../model/mapFilteredResponse';
import { PublishRouteRequest } from '../model/publishRouteRequest';
import { RetrieveImagesPaginatedRequest } from '../model/retrieveImagesPaginatedRequest';
import { RetrieveImagesPaginatedResponse } from '../model/retrieveImagesPaginatedResponse';
import { RouteDetailsResponse } from '../model/routeDetailsResponse';
import { RoutePathResponse } from '../model/routePathResponse';
import { RouteStatsResponse } from '../model/routeStatsResponse';
import { UpdateRouteRequest } from '../model/updateRouteRequest';
import { UserChangePasswordRequest } from '../model/userChangePasswordRequest';
import { UserInformationResponse } from '../model/userInformationResponse';
import { UserUpdateRequest } from '../model/userUpdateRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { DeleteRouteRequest } from '@api-client/model/deleteRouteRequest';


@Injectable()
export class DefaultService {

    protected basePath = 'https://7xf1cc0dn1.execute-api.eu-west-1.amazonaws.com/dev';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param published 
     * @param page 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRouteAllNotConfirmedGet(published: string, page: string, observe?: 'body', reportProgress?: boolean): Observable<AdminRetrieveRoutesResponse>;
    public adminRouteAllNotConfirmedGet(published: string, page: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AdminRetrieveRoutesResponse>>;
    public adminRouteAllNotConfirmedGet(published: string, page: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AdminRetrieveRoutesResponse>>;
    public adminRouteAllNotConfirmedGet(published: string, page: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (published === null || published === undefined) {
            throw new Error('Required parameter published was null or undefined when calling adminRouteAllNotConfirmedGet.');
        }

        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling adminRouteAllNotConfirmedGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (published !== undefined && published !== null) {
            queryParameters = queryParameters.set('published', <any>published);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-Fondazione-Portal-Users) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<AdminRetrieveRoutesResponse>(`${this.basePath}/admin/route/all-not-confirmed`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRouteAllNotConfirmedOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public adminRouteAllNotConfirmedOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public adminRouteAllNotConfirmedOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public adminRouteAllNotConfirmedOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/admin/route/all-not-confirmed`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRouteApproveImagesOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public adminRouteApproveImagesOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public adminRouteApproveImagesOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public adminRouteApproveImagesOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/admin/route/approve-images`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param approveImageRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRouteApproveImagesPost(approveImageRequest: ApproveImageRequest, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public adminRouteApproveImagesPost(approveImageRequest: ApproveImageRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public adminRouteApproveImagesPost(approveImageRequest: ApproveImageRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public adminRouteApproveImagesPost(approveImageRequest: ApproveImageRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (approveImageRequest === null || approveImageRequest === undefined) {
            throw new Error('Required parameter approveImageRequest was null or undefined when calling adminRouteApproveImagesPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-Fondazione-Portal-Users) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/admin/route/approve-images`,
            approveImageRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRouteApproveRouteOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public adminRouteApproveRouteOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public adminRouteApproveRouteOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public adminRouteApproveRouteOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/admin/route/approve-route`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param publishRouteRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRouteApproveRoutePut(publishRouteRequest: PublishRouteRequest, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public adminRouteApproveRoutePut(publishRouteRequest: PublishRouteRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public adminRouteApproveRoutePut(publishRouteRequest: PublishRouteRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public adminRouteApproveRoutePut(publishRouteRequest: PublishRouteRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (publishRouteRequest === null || publishRouteRequest === undefined) {
            throw new Error('Required parameter publishRouteRequest was null or undefined when calling adminRouteApproveRoutePut.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-Fondazione-Portal-Users) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Empty>(`${this.basePath}/admin/route/approve-route`,
            publishRouteRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param deleteRouteRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRouteDeleteRouteDelete(deleteRouteRequest: DeleteRouteRequest, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public adminRouteDeleteRouteDelete(deleteRouteRequest: DeleteRouteRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public adminRouteDeleteRouteDelete(deleteRouteRequest: DeleteRouteRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public adminRouteDeleteRouteDelete(deleteRouteRequest: DeleteRouteRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deleteRouteRequest === null || deleteRouteRequest === undefined) {
            throw new Error('Required parameter deleteRouteRequest was null or undefined when calling adminRouteDeleteRouteDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-Fondazione-Portal-Users) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.delete<Empty>(`${this.basePath}/admin/route/delete-route`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                body:deleteRouteRequest
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRouteDeleteRouteOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public adminRouteDeleteRouteOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public adminRouteDeleteRouteOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public adminRouteDeleteRouteOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/admin/route/delete-route`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRouteRetrieveImagesOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public adminRouteRetrieveImagesOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public adminRouteRetrieveImagesOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public adminRouteRetrieveImagesOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/admin/route/retrieve-images`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param retrieveImagesPaginatedRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRouteRetrieveImagesPost(retrieveImagesPaginatedRequest: RetrieveImagesPaginatedRequest, observe?: 'body', reportProgress?: boolean): Observable<RetrieveImagesPaginatedResponse>;
    public adminRouteRetrieveImagesPost(retrieveImagesPaginatedRequest: RetrieveImagesPaginatedRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RetrieveImagesPaginatedResponse>>;
    public adminRouteRetrieveImagesPost(retrieveImagesPaginatedRequest: RetrieveImagesPaginatedRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RetrieveImagesPaginatedResponse>>;
    public adminRouteRetrieveImagesPost(retrieveImagesPaginatedRequest: RetrieveImagesPaginatedRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (retrieveImagesPaginatedRequest === null || retrieveImagesPaginatedRequest === undefined) {
            throw new Error('Required parameter retrieveImagesPaginatedRequest was null or undefined when calling adminRouteRetrieveImagesPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-Fondazione-Portal-Users) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<RetrieveImagesPaginatedResponse>(`${this.basePath}/admin/route/retrieve-images`,
            retrieveImagesPaginatedRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRouteUpdateRouteOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public adminRouteUpdateRouteOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public adminRouteUpdateRouteOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public adminRouteUpdateRouteOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/admin/route/update-route`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param updateRouteRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminRouteUpdateRoutePost(updateRouteRequest: UpdateRouteRequest, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public adminRouteUpdateRoutePost(updateRouteRequest: UpdateRouteRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public adminRouteUpdateRoutePost(updateRouteRequest: UpdateRouteRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public adminRouteUpdateRoutePost(updateRouteRequest: UpdateRouteRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (updateRouteRequest === null || updateRouteRequest === undefined) {
            throw new Error('Required parameter updateRouteRequest was null or undefined when calling adminRouteUpdateRoutePost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-Fondazione-Portal-Users) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/admin/route/update-route`,
            updateRouteRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminUsersCreateUserOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public adminUsersCreateUserOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public adminUsersCreateUserOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public adminUsersCreateUserOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/admin/users/create-user`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param createUserRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminUsersCreateUserPost(createUserRequest: CreateUserRequest, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public adminUsersCreateUserPost(createUserRequest: CreateUserRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public adminUsersCreateUserPost(createUserRequest: CreateUserRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public adminUsersCreateUserPost(createUserRequest: CreateUserRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (createUserRequest === null || createUserRequest === undefined) {
            throw new Error('Required parameter createUserRequest was null or undefined when calling adminUsersCreateUserPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-Fondazione-Portal-Users) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/admin/users/create-user`,
            createUserRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminUsersEnableDisableOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public adminUsersEnableDisableOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public adminUsersEnableDisableOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public adminUsersEnableDisableOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/admin/users/enable-disable`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param enableDisableUserRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminUsersEnableDisablePost(enableDisableUserRequest: EnableDisableUserRequest, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public adminUsersEnableDisablePost(enableDisableUserRequest: EnableDisableUserRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public adminUsersEnableDisablePost(enableDisableUserRequest: EnableDisableUserRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public adminUsersEnableDisablePost(enableDisableUserRequest: EnableDisableUserRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (enableDisableUserRequest === null || enableDisableUserRequest === undefined) {
            throw new Error('Required parameter enableDisableUserRequest was null or undefined when calling adminUsersEnableDisablePost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-Fondazione-Portal-Users) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/admin/users/enable-disable`,
            enableDisableUserRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminUsersListGet(observe?: 'body', reportProgress?: boolean): Observable<ListUsersResponse>;
    public adminUsersListGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ListUsersResponse>>;
    public adminUsersListGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ListUsersResponse>>;
    public adminUsersListGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (DEV-Fondazione-Portal-Users) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ListUsersResponse>(`${this.basePath}/admin/users/list`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminUsersListOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public adminUsersListOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public adminUsersListOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public adminUsersListOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/admin/users/list`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminUsersUpdateUserOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public adminUsersUpdateUserOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public adminUsersUpdateUserOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public adminUsersUpdateUserOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/admin/users/update-user`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param userUpdateRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminUsersUpdateUserPost(userUpdateRequest: UserUpdateRequest, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public adminUsersUpdateUserPost(userUpdateRequest: UserUpdateRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public adminUsersUpdateUserPost(userUpdateRequest: UserUpdateRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public adminUsersUpdateUserPost(userUpdateRequest: UserUpdateRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userUpdateRequest === null || userUpdateRequest === undefined) {
            throw new Error('Required parameter userUpdateRequest was null or undefined when calling adminUsersUpdateUserPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-Fondazione-Portal-Users) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/admin/users/update-user`,
            userUpdateRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mapPinpointsRetrieveOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public mapPinpointsRetrieveOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public mapPinpointsRetrieveOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public mapPinpointsRetrieveOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/map/pinpoints/retrieve`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param mapFilteredRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mapPinpointsRetrievePost(mapFilteredRequest: MapFilteredRequest, observe?: 'body', reportProgress?: boolean): Observable<MapFilteredResponse>;
    public mapPinpointsRetrievePost(mapFilteredRequest: MapFilteredRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MapFilteredResponse>>;
    public mapPinpointsRetrievePost(mapFilteredRequest: MapFilteredRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MapFilteredResponse>>;
    public mapPinpointsRetrievePost(mapFilteredRequest: MapFilteredRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mapFilteredRequest === null || mapFilteredRequest === undefined) {
            throw new Error('Required parameter mapFilteredRequest was null or undefined when calling mapPinpointsRetrievePost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-Fondazione-Portal-Users) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MapFilteredResponse>(`${this.basePath}/map/pinpoints/retrieve`,
            mapFilteredRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param route 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mapRouteDetailsGet(route: string, observe?: 'body', reportProgress?: boolean): Observable<RouteDetailsResponse>;
    public mapRouteDetailsGet(route: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RouteDetailsResponse>>;
    public mapRouteDetailsGet(route: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RouteDetailsResponse>>;
    public mapRouteDetailsGet(route: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (route === null || route === undefined) {
            throw new Error('Required parameter route was null or undefined when calling mapRouteDetailsGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (route !== undefined && route !== null) {
            queryParameters = queryParameters.set('route', <any>route);
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-Fondazione-Portal-Users) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<RouteDetailsResponse>(`${this.basePath}/map/route/details`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mapRouteDetailsOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public mapRouteDetailsOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public mapRouteDetailsOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public mapRouteDetailsOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/map/route/details`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param route 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mapRoutePathGet(route: string, observe?: 'body', reportProgress?: boolean): Observable<RoutePathResponse>;
    public mapRoutePathGet(route: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RoutePathResponse>>;
    public mapRoutePathGet(route: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RoutePathResponse>>;
    public mapRoutePathGet(route: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (route === null || route === undefined) {
            throw new Error('Required parameter route was null or undefined when calling mapRoutePathGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (route !== undefined && route !== null) {
            queryParameters = queryParameters.set('route', <any>route);
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-Fondazione-Portal-Users) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<RoutePathResponse>(`${this.basePath}/map/route/path`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mapRoutePathOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public mapRoutePathOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public mapRoutePathOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public mapRoutePathOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/map/route/path`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param route 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mapRouteStatsGet(route: string, observe?: 'body', reportProgress?: boolean): Observable<RouteStatsResponse>;
    public mapRouteStatsGet(route: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RouteStatsResponse>>;
    public mapRouteStatsGet(route: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RouteStatsResponse>>;
    public mapRouteStatsGet(route: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (route === null || route === undefined) {
            throw new Error('Required parameter route was null or undefined when calling mapRouteStatsGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (route !== undefined && route !== null) {
            queryParameters = queryParameters.set('route', <any>route);
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-Fondazione-Portal-Users) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<RouteStatsResponse>(`${this.basePath}/map/route/stats`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public mapRouteStatsOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public mapRouteStatsOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public mapRouteStatsOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public mapRouteStatsOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/map/route/stats`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userChangePasswordOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public userChangePasswordOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public userChangePasswordOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public userChangePasswordOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/user/change-password`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param userChangePasswordRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userChangePasswordPost(userChangePasswordRequest: UserChangePasswordRequest, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public userChangePasswordPost(userChangePasswordRequest: UserChangePasswordRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public userChangePasswordPost(userChangePasswordRequest: UserChangePasswordRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public userChangePasswordPost(userChangePasswordRequest: UserChangePasswordRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userChangePasswordRequest === null || userChangePasswordRequest === undefined) {
            throw new Error('Required parameter userChangePasswordRequest was null or undefined when calling userChangePasswordPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (DEV-Fondazione-Portal-Users) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/user/change-password`,
            userChangePasswordRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userInfoGet(observe?: 'body', reportProgress?: boolean): Observable<UserInformationResponse>;
    public userInfoGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserInformationResponse>>;
    public userInfoGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserInformationResponse>>;
    public userInfoGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (DEV-Fondazione-Portal-Users) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<UserInformationResponse>(`${this.basePath}/user/info`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userInfoOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public userInfoOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public userInfoOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public userInfoOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/user/info`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers, 
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

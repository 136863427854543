import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef
} from "@angular/core";
import { DefaultService } from "@api-client/index";
import { RouteStatsResponse } from "@api-client/model/routeStatsResponse";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SlideUpDownPanel } from "@shared/animations/custom-animations";
import { AuthService } from "@shared/auth/auth.service";
import { LoadingService } from "@shared/services/loading.service";
import { UtilityService } from "@shared/services/utility.service";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";


@Component({
  selector: "app-dashboard-dialog",
  templateUrl: "./dashboard-dialog.component.html",
  styleUrls: ["./dashboard-dialog.component.scss"]
})
export class DashboardDialogComponent implements OnInit, OnDestroy {
  //@ViewChild(NgxMasonryComponent, { static: false }) masonry: NgxMasonryComponent;
  private _subscribers: Array<Subscription> = new Array<Subscription>();
  private _route: string;

  title: string = "";

  set route(value: string) {
    if (!value) return;
    this._route = value;
    this._load();
  }
  get route(): any {
    return this._route;
  }
  kpiChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          stacked: true,
          gridLines: {

            color: "rgba(0,0,0,.1)",
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          display: true,
          ticks: {
            beginAtZero: false
          },
          gridLines: {
            display: false,
            color: "rgba(0,0,0,.1)",
          },
        },
      ],
    },
    legend: { display: true, position: "bottom",
    labels: {
      fontSize: 12,
  } },
    plugins: {
      colors: {
        forceOverride: true,
      },
    },
  };
  public isLoading = {
    c1: true,
    c2: true,
    c3: true,
    c4: true,
    c5: true
  };
  constructor(
    private modalService: NgbModal,
    public toastr: ToastrService,
    private cdRef: ChangeDetectorRef,
    private dataControllerService: DefaultService,
    private loadingService: LoadingService,
    public utilityService: UtilityService
  ) {
  }
  ngOnInit() {

  }
  close() {
    this.modalService.dismissAll();
  }
  private _load() {
    this.dataControllerService.mapRouteStatsGet(this.route).subscribe((res: RouteStatsResponse) => {
      const data = res;
      this._loadChartSegment(data);
      this._loadChartSlop(data);
      this._loadChartDiscontinutity(data);
      this._loadChartTerrains(data);

    })
  }
  chartSegmentLabel: Array<string> = [];
  chartSegmentData: any;
  chartSegmentData2: any;
  private _loadChartSegment(data: RouteStatsResponse) {
    this.chartSegmentLabel = data.map((c, idx) => 's' + (idx + 1));
    const avg_roughnessData = [];
    const min_path_distData = [];
    data.forEach(c => {
      avg_roughnessData.push(c.avg_roughness.toFixed(2));
      min_path_distData.push(c.min_path_dist.toFixed(2));
    });
    
    const dataSet = [
      {
        label: 'avg_roughness',
        backgroundColor: "rgb(104, 58, 156)",
        borderColor: 'rgb(104, 58, 156)',
        hoverBackgroundColor: 'rgba(104, 58, 156, .6)',
        data: avg_roughnessData,
      }
    ];
    const dataSet2 = [
      {
        label: 'min_path_dist',
        backgroundColor: "rgb(104, 58, 156)",
        borderColor: 'rgb(104, 58, 156)',
        hoverBackgroundColor: 'rgba(104, 58, 156, .6)',
        data: min_path_distData,
      }
    ];
    this.chartSegmentData = dataSet;
    this.chartSegmentData2 = dataSet2;
    this.isLoading.c1 = false;
  }

  chartSlopeLabel: Array<string> = [];
  chartSlopeData: any;
  private _loadChartSlop(data: RouteStatsResponse) {
    this.chartSlopeLabel = data.map((c, idx) => 's' + (idx + 1));
    const negative_slopeData = [];
    const positive_slopeData = [];
    data.forEach(c => {
      negative_slopeData.push(c.negative_slope.toFixed(2));
      positive_slopeData.push(c.positive_slope.toFixed(2));
    });
    const dataSet = [

      {
        label: 'negative_slope',
        backgroundColor: "rgba(104, 58, 156, .6)",
        borderColor: 'rgba(104, 58, 156, .6)',
        hoverBackgroundColor: 'rgba(104, 58, 156, .4)',
        data: negative_slopeData,
      },
      {
        label: 'positive_slope',
        backgroundColor: "rgb(104, 58, 156)",
        borderColor: 'rgb(104, 58, 156)',
        hoverBackgroundColor: 'rgba(104, 58, 156, .6)',
        data: positive_slopeData,
      }
    ];
    this.chartSlopeData = dataSet;
    this.isLoading.c2 = false;
  }

  chartDiscontinuityLabel: Array<string> = [];
  chartDiscontinuityData: any;
  private _loadChartDiscontinutity(data: RouteStatsResponse) {
    this.chartDiscontinuityLabel = data.map((c, idx) => 's' + (idx + 1));
    const max_discontinuityData = [];
    const perc_discontinuityData = [];
    data.forEach(c => {
      max_discontinuityData.push(c.max_discontinuity.toFixed(2));
      perc_discontinuityData.push(c.perc_discontinuity.toFixed(2));
    });
    const dataSet = [

      {
        label: 'max_discontinuity',
        backgroundColor: "rgb(224, 247, 96)",
        borderColor: 'rgb(224, 247, 96)',
        hoverBackgroundColor: 'rgba(224, 247, 96,.6)',
        data: max_discontinuityData,
      },
      {
        label: 'perc_discontinuity',
        backgroundColor: "rgb(104, 58, 156)",
        borderColor: 'rgb(104, 58, 156)',
        hoverBackgroundColor: 'rgba(104, 58, 156, .6)',
        data: perc_discontinuityData,
      }
    ];
    this.chartDiscontinuityData = dataSet;
    this.isLoading.c3 = false;
  }


  chartTerrainsLabel: Array<string> = [];
  chartTerrainsData: any;
  private _loadChartTerrains(data: RouteStatsResponse) {
    this.chartTerrainsLabel = data.map((c, idx) => 's' + (idx + 1));
    const asphaltData = [];
    const badwoodenData = [];
    const buildingData = [];
    const bushData = [];
    const concreteData = [];
    const earthData = [];
    const fenceData = [];
    const foliageData = [];
    const grassData = [];
    const gravelData = [];
    const highgrassData = [];
    const pavementData = [];
    const rockyData = [];
    const sandData = [];
    const skyData = [];
    const stairsData = [];
    const treeData = [];
    const waterData = [];
    const woodenData = [];
    const NAData = [];
    
    data.forEach(c => {
      asphaltData.push(c.terrains.asphalt.toFixed(2));
      badwoodenData.push(c.terrains.badwooden.toFixed(2));
      buildingData.push(c.terrains.building.toFixed(2));
      bushData.push(c.terrains.bush.toFixed(2));
      concreteData.push(c.terrains.concrete.toFixed(2));
      earthData.push(c.terrains.earth.toFixed(2));
      fenceData.push(c.terrains.fence.toFixed(2));
      foliageData.push(c.terrains.foliage.toFixed(2));
      grassData.push(c.terrains.grass.toFixed(2));
      gravelData.push(c.terrains.gravel.toFixed(2));
      highgrassData.push(c.terrains.highgrass.toFixed(2));
      pavementData.push(c.terrains.pavement.toFixed(2));
      rockyData.push(c.terrains.rocky.toFixed(2));
      sandData.push(c.terrains.sand.toFixed(2));
      skyData.push(c.terrains.sky.toFixed(2));
      stairsData.push(c.terrains.stairs.toFixed(2));
      treeData.push(c.terrains.tree.toFixed(2));
      waterData.push(c.terrains.water.toFixed(2));
      woodenData.push(c.terrains.wooden.toFixed(2));
      NAData.push(c.terrains['N/A'].toFixed(2));
    });
    const dataSet = [

    
      
      
    
      
     
     
      {
        label: 'sky',
        backgroundColor: "rgb(141, 221, 255)",
        borderColor: 'rgb(141, 221, 255)',
        hoverBackgroundColor: 'rgba(141, 221, 255, .6)',
        data: skyData,
      },
     
     
      {
        label: 'water',
        backgroundColor: "rgb(62, 175, 247)",
        borderColor: 'rgb(62, 175, 247)',
        hoverBackgroundColor: 'rgba(62, 175, 247, .6)',
        data: waterData,
      },
       
     
      {
        label: 'foliage',
        backgroundColor: "rgb(118, 175, 96)",
        borderColor: 'rgb(118, 175, 96)',
        hoverBackgroundColor: 'rgba(118, 175, 96, .6)',
        data: foliageData,
      },
      {
        label: 'bush',
        backgroundColor: "rgb(46, 179, 6)",
        borderColor: 'rgb(46, 179, 6)',
        hoverBackgroundColor: 'rgba(46, 179, 6, .6)',
        data: bushData,
      },
      {
        label: 'grass',
        backgroundColor: "rgb(62, 156, 33)",
        borderColor: 'rgb(62, 156, 33)',
        hoverBackgroundColor: 'rgba(62, 156, 33, .6)',
        data: grassData,
      },
      {
        label: 'highgrass',
        backgroundColor: "rgb(55, 118, 36)",
        borderColor: 'rgb(55, 118, 36)',
        hoverBackgroundColor: 'rgba(55, 118, 36, .6)',
        data: highgrassData,
      },
      {
        label: 'tree',
        backgroundColor: "rgb(43, 124, 15)",
        borderColor: 'rgb(43, 124, 15)',
        hoverBackgroundColor: 'rgba(43, 124, 15, .6)',
        data: treeData,
      },
      {
        label: 'sand',
        backgroundColor: "rgb(229, 187, 85)",
        borderColor: 'rgb(229, 187, 85)',
        hoverBackgroundColor: 'rgba(229, 187, 85, .6)',
        data: sandData,
      },
      {
        label: 'stairs',
        backgroundColor: "rgb(190, 150, 29)",
        borderColor: 'rgb(190, 150, 29)',
        hoverBackgroundColor: 'rgba(190, 150, 29, .6)',
        data: stairsData,
      },
      {
        label: 'earth',
        backgroundColor: "rgb(181, 116, 24)",
        borderColor: 'rgb(181, 116, 24)',
        hoverBackgroundColor: 'rgba(181, 116, 24, .6)',
        data: earthData,
      },
      {
        label: 'wooden',
        backgroundColor: "rgb(192, 118, 78)",
        borderColor: 'rgb(192, 118, 78)',
        hoverBackgroundColor: 'rgba(192, 118, 78)',
        data: woodenData,
      },
       
      {
        label: 'badwooden',
        backgroundColor: "rgb(120, 59, 29)",
        borderColor: 'rgb(120, 59, 29)',
        hoverBackgroundColor: 'rgba(120, 59, 29, .6)',
        data: badwoodenData,
      },
      
      {
        label: 'fence',
        backgroundColor: "rgb(233, 97, 56)",
        borderColor: 'rgb(233, 97, 56)',
        hoverBackgroundColor: 'rgba(233, 97, 56, .6)',
        data: fenceData,
      },
      {
        label: 'building',
        backgroundColor: "rgb(129, 140, 126)",
        borderColor: 'rgb(129, 140, 126)',
        hoverBackgroundColor: 'rgba(129, 140, 126, .6)',
        data: buildingData,
      },
      {
        label: 'gravel',
        backgroundColor: "rgb(94, 94, 94)",
        borderColor: 'rgb(94, 94, 94)',
        hoverBackgroundColor: 'rgba(94, 94, 94, .6)',
        data: gravelData,
      },
      {
        label: 'concrete',
        backgroundColor: "rgb(128, 128, 128)",
        borderColor: 'rgb(128, 128, 128)',
        hoverBackgroundColor: 'rgba(128, 128, 128, .6)',
        data: concreteData,
      },
      {
        label: 'rocky',
        backgroundColor: "rgb(138, 138, 138)",
        borderColor: 'rgb(138, 138, 138)',
        hoverBackgroundColor: 'rgba(138, 138, 138, .6)',
        data: rockyData,
      },
      {
        label: 'asphalt',
        backgroundColor: "rgb(192, 192, 192)",
        borderColor: 'rgb(192, 192, 192)',
        hoverBackgroundColor: 'rgba(192, 192, 192, .6)',
        data: asphaltData,
      },
      {
        label: 'pavement',
        backgroundColor: "rgb(240, 240, 240)",
        borderColor: 'rgb(240, 240, 240)',
        hoverBackgroundColor: 'rgba(240, 240, 240, .6)',
        data: pavementData,
      },
      {
        label: 'N/A',
        backgroundColor: "rgb(223, 223, 223)",
        borderColor: 'rgb(223, 223, 223)',
        hoverBackgroundColor: 'rgba(223, 223, 223, .6)',
        data: NAData,
      }
    ];
    this.chartTerrainsData = dataSet;
    this.isLoading.c4 = false;
  }
  ngOnDestroy() {
    if (this._subscribers) this._subscribers.forEach(s => s.unsubscribe());
  }

}

import { map } from 'rxjs/operators';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef,
  Renderer2,
  Input,
  SimpleChanges,
  ChangeDetectorRef
} from "@angular/core";
import { DataStatus } from "@api-client/enum/data.enum";
import { DefaultService } from "@api-client/index";
import { ApproveImageRequest } from "@api-client/model/approveImageRequest";
import { RetrieveImagesPaginatedRequest } from "@api-client/model/retrieveImagesPaginatedRequest";
import { RetrieveImagesPaginatedResponse } from "@api-client/model/retrieveImagesPaginatedResponse";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { SlideUpDownPanel } from "@shared/animations/custom-animations";
import { AuthService } from "@shared/auth/auth.service";

import { FilterService } from "@shared/services/filter.service";
import { LoadingService } from "@shared/services/loading.service";
import { UtilityService } from "@shared/services/utility.service";
import { NgxMasonryComponent, NgxMasonryOptions } from "ngx-masonry";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { environment } from '@environments/environment';
import * as THREE from 'three';
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader.js';
import Stats from 'three/examples/jsm/libs/stats.module.js';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { ArcballControls } from 'three/examples/jsm/controls/ArcballControls.js';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
@Component({
  selector: "app-pcl-dialog",
  templateUrl: "./pcl-dialog.component.html",
  styleUrls: ["./pcl-dialog.component.scss"],
  animations: [SlideUpDownPanel]
})
export class PclDialogComponent implements OnInit, OnDestroy {
  @Output() reloadData: EventEmitter<any> = new EventEmitter();
  isLoading: boolean = true;
  @ViewChild('canvas_box') canvas: ElementRef;
  @ViewChild('modalBody') modalBody: ElementRef;

  private _subscribers: Array<Subscription> = new Array<Subscription>();
  private _ply_s3: string = "";
  set ply_s3(value: string) {
    this._ply_s3 = value;


  }
  get ply_s3() { return this._ply_s3 }
  container: any;
  camera: any;
  cameraTarget: any;
  scene: any;
  constructor(

    private translate: TranslateService,
    private modalService: NgbModal,
    public toastr: ToastrService,
    private cdRef: ChangeDetectorRef,
    private loadingService: LoadingService,
    public utilityService: UtilityService
  ) {
  }
  ngAfterViewInit() {
    if (this.ply_s3) {
      this.init()
    }
  }
  ngOnInit() {

  }
  close() {
    this.clearAll();
    this.modalService.dismissAll();
    //if(this.isChanged)this.utilityService.reloadAllData();
    this.cdRef.detectChanges();
  }
  private _getSize() {
    const w = window.innerWidth = (90 / 100) * 10000;
    const h = window.innerHeight = (90 / 100) * 10000;
    return {
      innerWidth: w,
      innerHeight: h,
    }
  }
  private init() {
    this.container = this.canvas.nativeElement;
    //document.body.appendChild( this.container );

    this.camera = new THREE.PerspectiveCamera(45, this._getSize().innerWidth / this._getSize().innerHeight, 0.25, 1000);
    // this.camera.position.set( 3, 0.15, 3 );
    // this.camera.position.z = 40;
    //this.camera.position.set( 1, 1, 1 );
    let pointLight = new THREE.PointLight(0xffffff, 0.4);

    this.camera.add(pointLight);
    // this.camera.lookAt( 0, 0, 0 );
    this.camera.zoom = 35;
    this.camera.updateProjectionMatrix()
    this.cameraTarget = new THREE.Vector3(0, 0, 0);


    this.scene = new THREE.Scene();
    //const axesHelper = new THREE.AxesHelper(5);
    //this.scene.add( axesHelper );    
    this.camera.position.set(.5, 2.5, 2.5); // Set position like this
    this.camera.lookAt(new THREE.Vector3(0, 0, 0));
    //this.camera.position.z = 10;
    //   this.scene.add(new THREE.AxesHelper(5))
    //   const light = new THREE.SpotLight()
    //   light.position.set(20, 20, 20)
    //   this.scene.add(light)      
    //   const camera = new THREE.PerspectiveCamera(
    //     75,
    //     window.innerWidth / window.innerHeight,
    //     0.1,
    //     1000
    // )
    //camera.position.z = 40
    this.load(this.ply_s3);


  }
  private mesh: any;
  private load(path) {
    const loader = new PLYLoader();
    //path = 'http://localhost:4200/assets/test-3.ply';
    const _self = this;
    let colors = [];
    loader.load(path, geometry => {

      //geometry.computeVertexNormals();
      geometry.computeVertexNormals();
      geometry.center();
      let geo = new THREE.BufferGeometry(geometry);
      const color1 = new THREE.Color("rgb(255, 255, 255)");
      const material = new THREE.MeshBasicMaterial({ color: color1, flatShading: true, size: 0.1 });
      const modelMaterial = new THREE.PointsMaterial({ color: color1, blending: THREE.AdditiveBlending, transparent: true, depthTest: false, vertexColors: true, size: 0.01 });

      //let mesh = new THREE.Mesh(geometry, material);

      _self.mesh = new THREE.Points(geometry, modelMaterial);

      _self.mesh.position.y = 0;
      _self.mesh.position.z = 0;
      _self.mesh.rotation.x = 0;
      //mesh.scale.multiplyScalar(1);

      //mesh.rotation.x = - Math.PI / 2;
      _self.mesh.scale.multiplyScalar(0.01);
      _self.mesh.frustumCulled = false;
      // mesh.castShadow = true;
      // mesh.receiveShadow = true;


      _self.mesh.traverse(function (obj) { obj.frustumCulled = false; });
      // const box = new THREE.BoxHelper( _self.mesh, 0xffff00 );
      // box.position.set(0,2.5,2.5);
      // _self.scene.add(box);
      _self.scene.add(_self.mesh);
      // _self.scene.add(new THREE.HemisphereLight(0x443333, 0x111122));
      // this.addShadowedLight(1, 1, 1, 0xffffff, 1.35);
      // this.addShadowedLight(0.5, 1, - 1, 0xffaa00, 1);
      _self.render(_self.mesh);
      _self.isLoading = false;
    }, undefined, function (error) {
      console.error(error);
      _self.isLoading = false;
    });
  }
  enlarge(b: boolean) {
    if (!this.mesh) return;
    const material = this.mesh.material;
    if (b) {
      material.size = material.size + 0.001;
    } else {
      material.size = material.size - 0.001;
    }
  }
  private renderer: any;
  render(mesh: any) {
    const _self = this;
    _self.renderer = new THREE.WebGLRenderer({ antialias: true });
    //renderer.setClearColor(new THREE.Color(0x000, 1.0));
    _self.renderer.setPixelRatio(window.devicePixelRatio);
    _self.renderer.setSize(_self.canvas.nativeElement.clientWidth, _self.canvas.nativeElement.clientHeight);
    _self.renderer.shadowMap.enabled = true;
    _self.container.appendChild(_self.renderer.domElement);

    const stats = new Stats();
    stats.domElement.style.position = 'absolute';
    stats.domElement.style.left = '0px';
    stats.domElement.style.top = '0px';
    stats.update();
    _self.container.appendChild(stats.dom);
    _self.camera.updateProjectionMatrix();


    const _render = function () {
      _self.renderer.render(_self.scene, _self.camera);
    };
    const orbit = new OrbitControls(_self.camera, _self.renderer.domElement);
    orbit.update();
    orbit.addEventListener('change', _render);

    const control = new TransformControls(_self.camera, _self.renderer.domElement);
    control.showY = false;
    control.showX = false;
    control.showZ = false;
    control.attach(mesh);
    this.scene.add(control);

    control.addEventListener('change', _render);
    control.addEventListener('dragging-changed', function (event) {

      orbit.enabled = !event.value;

    });

    window.addEventListener('resize', _self.onWindowResize);
    //const controls = new ArcballControls( _self.camera, renderer.domElement, this.scene );
    const animate = function () {
      requestAnimationFrame(animate);
      stats.update();
      // UPDATE CONTROLER:
      // required if controls.enableDamping or controls.autoRotate are set to true
      orbit.update();

      _self.renderer.render(_self.scene, _self.camera);
    };
    animate();


  }
  onWindowResize() {
    const _self = this;
    const aspect = _self._getSize().innerWidth / _self._getSize().innerHeight;

    _self.camera.aspect = aspect;
    _self.camera.updateProjectionMatrix();

    _self.camera.left = _self.camera.bottom * aspect;
    _self.camera.right = _self.camera.top * aspect;
    _self.camera.updateProjectionMatrix();

    _self.renderer.setSize(_self._getSize().innerWidth, _self._getSize().innerHeight);

    _self.renderer.render(this.scene, this.camera);

  }

  private clearAll() {
    window.removeEventListener('resize', this.onWindowResize);
    if (this.renderer) {
      this.renderer.dispose();
      //this.renderer?.clear();
    }
  }
  ngOnDestroy() {
    if (this._subscribers) this._subscribers.forEach(s => s.unsubscribe());
    this.clearAll();

  }


}

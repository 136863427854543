<div class="modal-header">
    <h4 class="modal-title">{{'LIST.EDIT_ITEM' | translate}}
    </h4>
    
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  
  </div>
  <form [formGroup]="lForm" >
  <div class="modal-body">
    <div class="form-group">
      <input type="text" formControlName="name" class="form-control" placeholder="{{'LIST.NAME'|translate}}"
        [ngClass]="{ 'is-invalid':  lf.name.invalid, 'is-valid': !lf.name.invalid  }"
        required>
     
    </div>
    <div class="form-group">
      <textarea  row="3" formControlName="description" class="form-control" placeholder="{{'LIST.DESCRIPTION'|translate}}"
        [ngClass]="{ 'is-invalid':  lf.description.invalid, 'is-valid': !lf.description.invalid  }"
        required></textarea>
     
    </div>
     
    
  </div>
  <div class="modal-footer">
    <div ngbDropdown>
    <button [disabled]="!lForm.valid || formSubmitted" type="submit" class="btn btn-primary round" (click)="saveChange()"><i class="ft-check mr-1"></i>{{'COMMON.SAVE_CHANGES'|translate}}</button>
    <button type="button" class="btn btn-outline-primary round" (click)="close()">{{'COMMON.CANCEL'|translate}}</button>
  </div>
  </div>
</form>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DefaultService } from '@api-client/index';
import { UpdateRouteRequest } from '@api-client/model/updateRouteRequest';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '@shared/services/loading.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-route-dialog',
  templateUrl: './edit-route-dialog.component.html',
  styleUrls: ['./edit-route-dialog.component.scss']
})
export class EditRouteDialogComponent implements OnInit {
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  
  lForm = new FormGroup({
    name: new FormControl('', {validators:[Validators.required], updateOn: 'blur'}  ),
    description: new FormControl('', {validators:[Validators.required], updateOn: 'blur'}  ),
    route_id:new FormControl('')
  });
  formSubmitted:boolean = false;
  @Input() set row(n:any){
    this.lForm.controls["name"].setValue(n.route_name);
    this.lForm.controls["description"].setValue(n.route_description);
    this.lForm.controls["route_id"].setValue(n.route_id);
  }

  private _subscribers: Array<Subscription> = new Array<Subscription>();
  constructor(public toastr: ToastrService,private loadingService: LoadingService,private modalService: NgbModal, private translate: TranslateService,private dataControllerService: DefaultService) {

   }

  ngOnInit(): void {
  }
  close() {
    this.modalService.dismissAll();
  }
  get lf() {
    return this.lForm.controls;
  }
  saveChange(){
    this.formSubmitted = true;
    this.loadingService.show();
    const req:UpdateRouteRequest = {
     
        description: this.lForm.controls["description"].value,
        name:this.lForm.controls["name"].value,
        route_id:this.lForm.controls["route_id"].value,
      
    }
    this.dataControllerService.adminRouteUpdateRoutePost(req).subscribe(res=>{
      if (res && res["errorMessage"]) {
        this.toastr.error(res["errorMessage"], '');
      }
      else {
        this.toastr.success('', this.translate.instant("COMMON.MESSAGES.SUCCESS_TITLE"));
        this.onSave.emit(req);
      }
      this.loadingService.hide();
      this.formSubmitted = false;
    })
  }
  ngOnDestroy(): void {
    if (this._subscribers)this._subscribers.forEach(s => s.unsubscribe());
  }
}

/**
 * FONDAZIONE-PORTAL
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-04-08T09:00:29Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ListUsersResponsePortalUsers { 
    uuid?: string;
    nickname?: string;
    firs_nName?: string;
    last_name?: string;
    email?: string;
    role?: string;
    enabled?:boolean;
}

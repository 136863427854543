
<div class="chart-container">
    <div *ngIf="isLoading && !noLoading" class="loading">
        <img src="assets/img/oval.svg" class="loading-icon" />
      </div>
      <ng-container *ngIf="!isLoading">
    <ng-container *ngIf="singleDataSet;else alternative_chart">
        <canvas baseChart 
        [data]="chartData" 
        [labels]="chartLabels" 
        [options]="chartOptions"
        [plugins]="chartPlugins" 
        [legend]="chartLegend" 
        [chartType]="chartType"
        [colors]="chartColors">
    </canvas>
    </ng-container>
    <ng-template #alternative_chart>
        <canvas baseChart 
        [datasets]="chartData" 
        [labels]="chartLabels" 
        [options]="chartOptions"
        [plugins]="chartPlugins" 
        [legend]="chartLegend" 
        [chartType]="chartType">
    </canvas>
    </ng-template>
   
            </ng-container>
</div>
<div class="modal-header">
	<h2 class="accent-font"><span>{{'MAP.LABEL.pcl' | translate}}</span></h2>
	<button type="button" class="close" aria-label="Close" (click)="close()">
		<span aria-hidden="true">&times;</span>
	</button>
	<a target="_blank" [href]="ply_s3" class="btn-download " download container="body"
			[ngbTooltip]="'Download ply' | translate">
			<span class="ft-download"></span>
		</a>
	<div class="controls">
		<button (click)="enlarge(true)">+</button>
		<button (click)="enlarge(false)">-</button>
		
	</div>

</div>

<div class="modal-body" #modalBody>
	<div #canvas_box id="canvas"></div>
	<app-spinner [static]="true" [show]="isLoading"></app-spinner>
</div>
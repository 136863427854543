<nav  class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}"
  [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center "
        data-toggle="collapse" (click)="toggleSidebar()">
        <i class="icon-fm-menu font-large-1"></i>
      </div>
      <ul class="navbar-nav" >
        <li class="nav-item">
          <div class="logo"><a class="logo-text" [routerLink]="['/maps']" >
              <div class="logo-img"><img class="logo-img" alt="Keye logo" [src]="defaultLogoUrl" *ngIf="defaultLogoUrl"></div>
            </a></div>
        </li>
        <!-- <li class="nav-item">
          <span _ngcontent-mhq-c364="" class="badge bg-light-primary ml-4 small">{{organization?.group_name}}</span>
        </li> -->
       
      </ul>
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item mr-2 d-none d-lg-block">
              <a class="nav-link apptogglefullscreen" [routerLink]="['/maps']" *ngIf="config?.layout.menuPosition === 'Top'"><i class=" {{toggleClass}} font-medium-3"></i></a>
            </li>
           
          </ul> 
        </div>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav" (clickOutside)="toggleSearchOpenClass(false)">
        
          <li class="nav-item mr-2 d-none d-lg-block">
            <a class="nav-link apptogglefullscreen" id="navbar-fullscreen" href="javascript:;" appToggleFullscreen
              (click)="ToggleClass()"><i class=" {{toggleClass}} font-medium-3"></i></a>
          </li>
          <li class="nav-item mr-2 d-none d-lg-block" *ngIf="config.layout.navbar.showDownload">
            <a class="nav-link apptogglefullscreen" 
              (click)="utilityService.requestDownload()"><i class="ft-download font-medium-3"></i></a>
          </li>
        
          <li class="dropdown nav-item mr-1" placement="bottom-left" display="static" ngbDropdown >
            <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic2"
              href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
              <div class="user d-md-flex d-none mr-2">
                <span class="text-right accent-font mb-1">{{(user | async)?.attributes["email"]}}</span>
                <span class="text-right text-muted font-small-2 accent-font">{{userInfo?.role || '-'}}</span>
              </div>
              <img class="avatar" src="assets/img/portrait/small/avatar.svg" alt="avatar" height="35" width="35" />
            </a>
            <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0 bg-dark" aria-labelledby="dropdownBasic2"
              ngbDropdownMenu>
              <a class="dropdown-item white" routerLink="/pages/change-password" >
                <div class="d-flex align-items-center">
                  <i class="icon-key mr-2"></i><span>{{'HEADER.CHANGE_PASSWORD' | translate}}</span>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item white" (click)="logout()">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>{{'HEADER.LOGOUT' | translate}}</span>
                </div>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>





</nav>

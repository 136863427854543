import { RouteInfo } from './vertical-menu.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  // {
  //   path: '/dashboard', title: 'Dashboard', icon: 'icon-pie-chart', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  // },
  {
    path: '/maps', title: 'NAVBAR.MENU_ITEMS.MAP', icon: 'icon-fm-map', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  // {
  //   path: '/dashboard', title: 'NAVBAR.MENU_ITEMS.DASHBOARD_POTHOLES', icon: 'icon-fm-stat', class: '', badge: '', badgeClass: '', isExternalLink: false, 
  //   submenu:  []
  // },
  {
    path: '/admin/users-list', title: 'NAVBAR.MENU_ITEMS.USERS', icon: 'icon-fm-users', class: '', badge: '', badgeClass: '', isExternalLink: false, isAdmin:true,
    submenu:  []
  },
  {
    path: '/admin/devices-list', title: 'NAVBAR.MENU_ITEMS.DEVICES', icon: 'icon-fm-mobile-devices small', class: '', badge: '', badgeClass: '', isExternalLink: false, isAdmin:true,
    submenu:  []
  },
  {
    path: '/admin/routes-list', title: 'NAVBAR.MENU_ITEMS.ROUTES', icon: 'icon-fm-settings', class: '', badge: '', badgeClass: '', isExternalLink: false, isAdmin:true,
    submenu:  []
  }
];
